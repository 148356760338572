// Fonts
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4.css";
@import "~bootstrap-daterangepicker/daterangepicker.css";



/*
 * Sidebar
 */

 .navbar-toggler{
   border: none;
 }

 .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
  margin-left: 0
}


.dropright .dropdown-toggle::after {
  vertical-align: 0
}
.dropdown-toggle::after {
  position: relative;
  top: 2px;
  opacity: .8
}
.dropright .dropdown-toggle::after {
  top: 0
}
.dropdown-toggle::after {
  position: relative;
  top: 2px;
  opacity: .8;
  margin-left: 5px
}


 .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 55px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 55px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: $primary;
  }
  
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
      position: -webkit-sticky;
      position: sticky;
    }
  }
  
  .sidebar .nav-link {
    font-weight: 500;
    color: $white;
  }
  
  .sidebar .nav-link .feather {
    margin-right: 4px;
    color: $off_white;
  }
  
  .sidebar .nav-link.active {
    color: $secondary;
  }
  
  .sidebar .nav-link:hover .feather,
  .sidebar .nav-link.active .feather {
    color: inherit;
  }
  
  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }
  
  /*
 * Content
 */

[role="main"] {
    padding-top: 55px; /* Space for fixed navbar */
  }
  

  /*
 * Cover
 */
.cover {
  padding: 0 1.5rem;
}
.cover .btn-lg {
  padding: .75rem 1.25rem;
  font-weight: 700;
}

.outer-divider {
  width: 1px;
  height: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
}
.inner-divider {
  position: absolute;
  width:100%;
  height: 40%;
  background: #d2d2d2;
  top: 30%;
  box-shadow: 0px 0px 30px 20px #d2d2d2;
}


/*
 * tables
 */

.select-costi-centri{
  margin-bottom: 0;
}

.thead-primary {
  background-color: $primary;
  color: $white;
}

th{
  font-weight: 700;
}

.table td, .table th{
  vertical-align: middle;
}

@mixin width {
  width: auto !important;
}

table {
  background-color: $white;
  &.table-fit {
      @include width;
      table-layout: auto !important;
      thead th, tfoot th  {
          @include width;
      }
      tbody td, tfoot td {
          @include width;
      }
  }
}

/*
 * Commessa detail
 */

.commessa-details{
  .row{
    padding-top: .25rem;
    padding-bottom: .25rem;
    align-items: center;
    :nth-child(2){
      margin-right: 2rem;
    }
    :nth-child(4){
      margin-right: 2rem;
    }
  }
  h6{
    font-weight: 700;
  }
}

.table-costo-commessa{
  tbody{
    tr{
      :nth-last-child(1){
        text-align: right;
      }
      
    } 
  }
}

.table-ricavo-commessa{
  tbody{
    tr{
      :nth-child(1){
        color: $primary;
      }
      :nth-child(2){
        text-align: right;
      }
      
    } 
  }
}

/*
 * General
 */
.table-costo-commessa,.table-ricavo-commessa{

  .form-control{
    padding: 0;
  }

}

.form-group.required label:after {
  content:"*";
  color:red;
}